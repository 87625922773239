import PropTypes from 'prop-types';

import {styled} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import Iconify from "src/components/iconify";

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isMobileVariant'
})(({isMobileVariant}) => ({
  '& .MuiInputBase-root': {
    background: '#ffffff'
  },
  ...(isMobileVariant && {
      '& .MuiInputBase-input': {
        width: 0
      }
    }
  )
}));

function KuliguliSearchInput({isMobileVariant, ...other}) {
  return <StyledTextField
    isMobileVariant={isMobileVariant}
    variant="outlined"
    placeholder="Wpisz czego szukasz..."
    {...other}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Iconify icon="eva:search-fill" sx={{ml: 1, color: 'text.disabled'}}/>
        </InputAdornment>
      ),
    }}/>;

}

KuliguliSearchInput.propTypes = {
  isMobileVariant: PropTypes.bool
};

export default KuliguliSearchInput;
