'use client';

import React from "react";
import PropTypes from 'prop-types';

import Stack from "@mui/material/Stack";

import {paths} from "src/routes/paths";

import CardButton from "src/components/card-button";

function HomepageMealTypes({mealTypeList}) {
  const preparedMealTypesData = mealTypeList
    .filter(mealType => mealType.showOnHomepage)
    .map(mealType => ({
        code: mealType.value,
        title: mealType.label,
        link: paths.recipe.browseWithParams({mealTypes: mealType.value}),
        icon: mealType?.homepageIcon?.url
      })
  )

  return (
    <Stack key="homepage-meal-types" direction="row" useFlexGap sx={{ flexWrap: "wrap", justifyContent: "space-evenly" }} spacing={2}>
      {
        preparedMealTypesData.map(mealType => (
          <CardButton key={`${mealType.code}-card-button`} title={mealType.title} link={mealType.link} icon={mealType.icon} />
        ))
      }
    </Stack>
  );
}

HomepageMealTypes.propTypes = {
  mealTypeList: PropTypes.array
};

export default HomepageMealTypes;
